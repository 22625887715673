@tailwind base;
@tailwind components;
@tailwind utilities;


.RickSig {
  font-family: "Ms Madi", cursive;
  font-weight: 400;
  font-style: normal;
}


.RickFont {
  font-family: "Karla", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}



.table-row {
  background-color: #f0f0f0; /* Change to desired hover color */
  text-align: left;
}

.table-row-dark {
  background-color: #104895; /* Change to desired hover color */
  
}

.table-row:hover {
  background-color: #ffffff; /* Change to desired hover color */
  
}

.table-row:not(:last-child) {
  border-bottom: 2px solid #ccc; /* Change to desired border color */
}
.table-padding {
  padding: 5px;
}

